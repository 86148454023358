
import validator from "validator";

export default {
  data() {

    return {
      valid: false,

      requiredRules: [
        v => !!v || this.$t('common.errors.IS_REQUIRED')
      ],

      emailRules: [
        v => !!v || this.$t('common.errors.IS_REQUIRED'),
        v => validator.isEmail(v) || this.$t('common.errors.INVALID_EMAIL')
      ]
    }

  }
}