<template>
  <v-dialog v-model="display" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
        dense
          v-bind="textFieldProps"
          :disabled="disabled"
          :loading="loading"
          :label="label"
          :value="formattedDatetime"
          v-on="on"
          :rules="required ? requiredRules : []"
          readonly
          hide-details
        solo
        height="35px"
        style="width: 160px !important;font-size: 16px !important;"
      >
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear color="primary" indeterminate absolute height="2"></v-progress-linear>
          </slot>
        </template>

        <template v-slot:append>
          <v-icon color="primary" size="16" class="mt-1">far fa-chevron-down</v-icon>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>fas fa-calendar-week</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>far fa-clock</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              :min="minDate"
              v-model="date"
              v-bind="datePickerProps"
              @input="showTimePicker"
              full-width />
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              :min="minTime"
                ref="timer"
                class="v-time-picker-custom"
                v-model="time"
                format="24hr"
                full-width
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="lighten-1" text @click.native="clearHandler">{{ clearText ? clearText : $t('common.datetime-picker.buttons.clear') }}</v-btn>
          <v-btn color="primary darken-1" text @click="okHandler">{{ okText ? okText : $t('common.datetime-picker.buttons.ok') }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import formatter from "@/mixins/formatter";
import formRules from "@/mixins/formRules";
import {DateTime} from "luxon";

const DEFAULT_DATE = ""
const DEFAULT_TIME = '00:00:00'
const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy'
const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_DIALOG_WIDTH = 340

export default {
  name: 'InputDatetimePicker',

  model: {
    prop: 'datetime',
    event: 'input'
  },

  mixins: [formatter, formRules],

  props: {
    datetime: {
      type: [Date, String],
      default: null
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    clearText: {
      type: String,
      default: null
    },
    okText: {
      type: String,
      default: null
    },
    textFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    },
    required: {type: Boolean, default: false}
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
      minDate: DateTime.now().toString(),
      minTime: DateTime.now().toFormat("HH:mm")
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      console.log(this.datetime);
      if (!this.datetime) {
        return;
      }

      let initDateTime;
      if (this.datetime instanceof Date) {
        initDateTime = this.datetime.toISOString();
      } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
        initDateTime = this.datetime;
      }

      this.date = this.formatDate(initDateTime, "ISO", "yyyy-MM-dd", false);
      this.time = this.formatDate(initDateTime, "ISO", "HH:mm", false);
    },

    okHandler() {
      this.resetPicker()
      this.$emit('input', this.selectedDatetime)
    },
    clearHandler() {
      this.resetPicker()
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit('input', null)
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker() {
      this.activeTab = 1;

    }
  },

  computed: {

    formattedDatetime() {
      return this.selectedDatetime ? this.formatDate(this.selectedDatetime, "ISO", "dd/MM/yy à HH'h'mm", false) : ''
    },

    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + 'T' + this.time
        if (this.time.length === 5) {
          datetimeString += ':00'
        }
        return datetimeString;
      } else {
        return null
      }
    },

    dateSelected() {
      return !this.date
    }
  },

  watch: {
    datetime: function() {
      this.init()
    },

    date: function(newValue, oldValue) {
      //console.log(newValue);
      if (DateTime.fromISO(this.date) > DateTime.now())
        this.minTime = null;
      else
        this.minTime = DateTime.now().toFormat("HH:mm");
    }
  }
}
</script>